<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-detalle-tepviajeinterno"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Detalle Viaje</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Viajes"
                  data-toggle="tab"
                  href="#Viajes"
                  >VIAJE
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Trazabilidad"
                  data-toggle="tab"
                  href="#Trazabilidad"
                  @click="getTraza(), showGeocercas()"
                  >Trazabilidad
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade active show"
                v-if="data_viaje"
                id="Viajes"
              >
                <div class="card-body">
                  <div class="row">
                    <!-- Viaje -->
                    <div class="col-md-12">
                      <div
                        class="position-relative p-3 m-2 bg-light"
                        style="font-size: 0.85em"
                      >
                        <div class="ribbon-wrapper ribbon-lg">
                          <div
                            class="ribbon"
                            :class="
                              data_viaje.estado == 1
                                ? 'badge-info'
                                : data_viaje.estado == 2
                                ? 'badge-warning'
                                : data_viaje.estado == 3
                                ? 'badge-success'
                                : data_viaje.estado == 4
                                ? 'badge-primary'
                                : data_viaje.estado == 5
                                ? 'badge-info'
                                : data_viaje.estado == 6
                                ? 'badge-danger'
                                : data_viaje.estado == 7
                                ? 'badge-danger'
                                : data_viaje.estado == 8
                                ? 'badge-primary'
                                : 'badge-secundary'
                            "
                          >
                            {{ data_viaje.nEstado }}
                          </div>
                        </div>
                        <div class="row">
                          <!-- Detalle -->
                          <div
                            class=" col-md-6 d-flex align-items-stretch flex-column"
                          >
                            <div class="card bg-white d-flex flex-fill">
                              <div class="card-header">
                                <h5 class="card-title">Datos Servicio</h5>
                              </div>
                              <div class="card-body">
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Numero Servicio:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{ data_viaje.id }}
                                  </p>
                                </div>
                                <div class="row">
                                  <p class="col-md-6" v-if="data_viaje.user">
                                    <b>Usuario Responsable:</b>
                                  </p>
                                  <p class="col-md-6" v-if="data_viaje.user">
                                    {{ data_viaje.user.name }}
                                  </p>
                                </div>
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Dirección origen:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{ data_viaje.direccion_origen }}
                                  </p>
                                </div>
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Dirección destino:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{ data_viaje.direccion_destino }}
                                  </p>
                                </div>
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Transportadora:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{
                                      data_viaje.transportadora
                                        ? data_viaje.transportadora.razon_social
                                        : ""
                                    }}
                                  </p>
                                </div>
                                <div
                                  class="row"
                                  v-if="data_viaje.razon_cancelacion"
                                >
                                  <p class="col-md-6">
                                    <b>Razón Cancelación:</b>
                                  </p>
                                  <p class="col-md-6 text-danger">
                                    {{ data_viaje.razon_cancelacion }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class=" col-md-6 d-flex align-items-stretch flex-column"
                          >
                            <div
                              class="card bg-white d-flex flex-fill"
                              v-if="data_viaje.tep_fechas_viajes"
                            >
                              <div class="card-header">
                                <h5 class="card-title">Fechas</h5>
                              </div>
                              <div class="card-body">
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Fecha inicio:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{ data_viaje.fecha_ini }}
                                  </p>
                                </div>
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Fecha fin:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{ data_viaje.fecha_fin }}
                                  </p>
                                </div>
                                <div
                                  class="row"
                                  v-if="
                                    data_viaje.solicitudes.tipo_operacion != 3
                                  "
                                >
                                  <p class="col-md-6">
                                    <b>Fecha llegada conductor:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{
                                      data_viaje.tep_fechas_viajes.length > 0
                                        ? data_viaje.tep_fechas_viajes[0]
                                            .fecha_llegada_app_conductor
                                        : ""
                                    }}
                                  </p>
                                </div>
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Fecha inicio usuario:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{
                                      data_viaje.tep_fechas_viajes.length > 0
                                        ? data_viaje.tep_fechas_viajes[0]
                                            .fecha_ini_app_user
                                        : ""
                                    }}
                                  </p>
                                </div>
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Fecha inicio conductor:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{
                                      data_viaje.tep_fechas_viajes.length > 0
                                        ? data_viaje.tep_fechas_viajes[0]
                                            .fecha_ini_app_conductor
                                        : ""
                                    }}
                                  </p>
                                </div>
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Fecha fin usuario:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{
                                      data_viaje.tep_fechas_viajes.length > 0
                                        ? data_viaje.tep_fechas_viajes[0]
                                            .fecha_fin_app_user
                                        : ""
                                    }}
                                  </p>
                                </div>
                                <div class="row">
                                  <p class="col-md-6">
                                    <b>Fecha fin conductor:</b>
                                  </p>
                                  <p class="col-md-6">
                                    {{
                                      data_viaje.tep_fechas_viajes.length > 0
                                        ? data_viaje.tep_fechas_viajes[0]
                                            .fecha_fin_app_conductor
                                        : ""
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Vehículo -->
                          <div
                            class="
                              col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                          >
                            <div class="card bg-white d-flex flex-fill">
                              <div class="card-header">
                                <h5 class="card-title">
                                  Vehículo
                                </h5>
                              </div>
                              <div class="card-body">
                                <div class="row" v-if="data_viaje.vehiculo">
                                  <div
                                    :class="
                                      data_viaje.vehiculo.link_fotografia
                                        ? 'col-md-7'
                                        : 'col-md-12'
                                    "
                                  >
                                    <h2 class="lead">
                                      <b>{{ data_viaje.vehiculo.placa }}</b>
                                    </h2>
                                    <p
                                      class="text-muted text-sm"
                                      v-if="data_viaje.transportadora"
                                    >
                                      <b>Transportadora: </b>
                                      {{
                                        data_viaje.transportadora.razon_social
                                      }}
                                    </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-car"></i
                                        ></span>
                                        Tipo Vehículo:
                                        {{
                                          data_viaje.vehiculo.tipo_vehiculo
                                            .nombre
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    class="col-md-5 text-center"
                                    v-if="data_viaje.vehiculo.link_fotografia"
                                  >
                                    <img
                                      :src="
                                        uri_docs +
                                          data_viaje.vehiculo.link_fotografia
                                      "
                                      alt="Vehículo"
                                      class="img-bordered img-rounded img-fluid"
                                    />
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="col-md-12 text-center">
                                    <h4 class="text-muted">Sin asignar</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Conductor -->
                          <div
                            class="
                              col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                            v-if="data_viaje.solicitudes.tipo_operacion != 3"
                          >
                            <div class="card bg-white d-flex flex-fill">
                              <div class="card-header">
                                <h5 class="card-title">
                                  Conductor
                                </h5>
                              </div>
                              <div class="card-body">
                                <div class="row" v-if="data_viaje.conductor">
                                  <div
                                    :class="
                                      data_viaje.conductor.link_fotografia
                                        ? 'col-md-7'
                                        : 'col-md-12'
                                    "
                                  >
                                    <h2 class="lead">
                                      <b
                                        >{{ data_viaje.conductor.nombres }}
                                        {{ data_viaje.conductor.apellidos }}
                                      </b>
                                    </h2>
                                    <p
                                      v-if="data_viaje.encuesta"
                                      class="text-muted text-sm"
                                    >
                                      <b>Calificación: </b>
                                      {{ encu.calificacion }}
                                      <i class="fas fa-star"></i>
                                    </p>
                                    <p v-else class="text-muted text-sm">
                                      <b>Calificación: </b>
                                      Sin calificación
                                    </p>
                                    <p class="text-muted text-sm">
                                      <b>Documento: </b>
                                      {{
                                        data_viaje.conductor.numero_documento
                                      }}
                                    </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-envelope"></i
                                        ></span>
                                        E-mail:
                                        {{ data_viaje.conductor.email }}
                                      </li>
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-mobile"></i
                                        ></span>
                                        Celular Principal:
                                        {{
                                          data_viaje.conductor.celular_principal
                                        }}
                                      </li>
                                      <li class="small">
                                        <span class="fa-li"
                                          ><i class="fas fa-lg fa-phone"></i
                                        ></span>
                                        Celular Alterno:
                                        {{
                                          data_viaje.conductor.celular_alterno
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    class="col-md-5 text-center"
                                    v-if="data_viaje.conductor.link_fotografia"
                                  >
                                    <img
                                      :src="
                                        uri_docs +
                                          data_viaje.conductor.link_fotografia
                                      "
                                      alt="Conductor"
                                      class="img-bordered img-rounded img-fluid"
                                    />
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="col-md-12 text-center">
                                    <h4 class="text-muted">Sin asignar</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Odómetro -->
                    <div class="col-md-12">
                      <div class="position-relative p-3 m-2 bg-light">
                        <div class="ribbon-wrapper ribbon-lg">
                          <div class="ribbon bg-info">Odómetro</div>
                        </div>
                        <div
                          class="row"
                          v-if="
                            data_viaje.odometro_ini ||
                              data_viaje.foto_odometro_ini ||
                              data_viaje.odometro_fin ||
                              data_viaje.foto_odometro_fin
                          "
                        >
                          <div class="col-md-12">
                            <div class="row">
                              <div
                                class="
                                  col-md-6
                                  d-flex
                                  align-items-stretch
                                  flex-column
                                "
                                v-if="data_viaje.odometro_ini"
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div
                                    class="
                                      card-header
                                      text-muted
                                      border-bottom-0
                                    "
                                  >
                                    Odómetro Inicial
                                  </div>
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <h2 class="lead">
                                          <b>{{ data_viaje.odometro_ini }}</b>
                                        </h2>
                                      </div>
                                      <div
                                        class="col-md-8 text-center"
                                        v-if="data_viaje.foto_odometro_ini"
                                      >
                                        <img
                                          @click="
                                            getImage(
                                              data_viaje.foto_odometro_ini
                                            )
                                          "
                                          :src="
                                            uri_docs +
                                              data_viaje.foto_odometro_ini
                                          "
                                          alt="Odómetro Inicial"
                                          class="
                                            img-bordered img-rounded img-fluid
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="
                                  col-md-6
                                  d-flex
                                  align-items-stretch
                                  flex-column
                                "
                                v-if="data_viaje.odometro_fin"
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div
                                    class="
                                      card-header
                                      text-muted
                                      border-bottom-0
                                    "
                                  >
                                    Odómetro Final
                                  </div>
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <h2 class="lead">
                                          <b>{{ data_viaje.odometro_fin }}</b>
                                        </h2>
                                      </div>
                                      <div
                                        class="col-md-8 text-center"
                                        v-if="data_viaje.foto_odometro_fin"
                                      >
                                        <img
                                          @click="
                                            getImage(
                                              data_viaje.foto_odometro_fin
                                            )
                                          "
                                          :src="
                                            uri_docs +
                                              data_viaje.foto_odometro_fin
                                          "
                                          alt="Odómetro Final"
                                          class="
                                            img-bordered img-rounded img-fluid
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="col-md-12 text-center p-5">
                            <h4 class="text-muted">Sin registrar</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="tab-pane" id="Viajes">
                <div class="card-body">
                  <div class="info-box bg-gradient-warning">
                    <span class="info-box-icon"
                      ><i class="fas fa-exclamation-triangle"></i
                    ></span>
                    <div class="info-box-content">
                      <span class="info-box-text"
                        >No tiene un viaje asignado</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane"
                v-if="data_viaje != null && traza_viaje != null"
                id="Trazabilidad"
              >
                <div class="card-body" v-if="traza_viaje">
                  <div class="row">
                    <div class="col-md-8">
                      <gmap-map
                        :center="center"
                        :zoom="zoom"
                        style="width: 100%; height: 650px"
                      >
                        <gmap-marker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :icon="m.icon"
                          :animation="2"
                          @click="toggleInfoWindow(m, index, m.info)"
                        ></gmap-marker>
                        <gmap-marker
                          :key="index"
                          v-for="(n, index) in markers_viaje"
                          :position="n.position"
                          :icon="n.icon"
                          :animation="3"
                          @click="toggleInfoWindow(n, index, n.info)"
                        ></gmap-marker>
                        <gmap-info-window
                          :options="infoOptions"
                          :position="infoWindowPos"
                          :opened="infoWinOpen"
                          @closeclick="infoWinOpen = false"
                        >
                          <div v-html="infoContent"></div>
                        </gmap-info-window>
                        <gmap-polygon
                          v-for="poligono in poligonos"
                          :options="{ fillColor: poligono.color }"
                          :key="poligono.id"
                          :paths="poligono.path"
                          :editable="false"
                          :draggable="false"
                          @click="
                            toggleInfoWindowPoli(
                              poligono,
                              poligono.id,
                              poligono.info
                            )
                          "
                        />
                        <gmap-circle
                          v-for="circular in circunferencias"
                          :key="circular.id"
                          :center="circular.center"
                          :radius="circular.radius"
                          :info="circular.info"
                          @click="
                            toggleInfoWindowCir(
                              circular,
                              circular.id,
                              circular.info
                            )
                          "
                        ></gmap-circle>
                      </gmap-map>
                    </div>
                    <div class="col-md-4">
                      <!-- Origen y destino -->
                      <table
                        class="table table-sm table-striped table-bordered"
                      >
                        <thead class="thead-dark">
                          <tr>
                            <th colspan="3" class="text-center">Viaje</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              <span class="badge bg-olive">Origen</span>
                            </td>
                            <td>
                              {{ data_viaje.direccion_origen }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">
                              <span class="badge bg-pink">Destino</span>
                            </td>
                            <td>
                              {{ data_viaje.direccion_destino }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">
                              <img
                                src="/img/volante1.png"
                                class="figure-img img-fluid rounded"
                              />
                            </td>
                            <td>
                              Inicio Conductor -
                              {{ data_viaje.lat_long_ini_conductor }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">
                              <img
                                src="/img/volante2.png"
                                class="figure-img img-fluid rounded"
                              />
                            </td>
                            <td>
                              Fin Conductor -
                              {{ data_viaje.lat_long_fin_conductor }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">
                              <img
                                src="/img/user1.png"
                                class="figure-img img-fluid rounded"
                              />
                            </td>
                            <td>
                              Inicio Usuario -
                              {{ data_viaje.lat_long_ini_user }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">
                              <img
                                src="/img/user.png"
                                class="figure-img img-fluid rounded"
                              />
                            </td>
                            <td>
                              Fin Usuario - {{ data_viaje.lat_long_fin_user }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">
                              <img
                                src="/img/icon_punto_g.png"
                                class="figure-img img-fluid rounded"
                              />
                            </td>
                            <td>Llegada - {{ data_viaje.lat_long_llegada }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="tab-pane" id="Trazabilidad">
                <div class="card-body">
                  <div class="info-box bg-gradient-warning">
                    <span class="info-box-icon"
                      ><i class="fas fa-exclamation-triangle"></i
                    ></span>
                    <div class="info-box-content">
                      <span class="info-box-text"
                        >Usted aun no tiene trazabilidad</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TepViajeInternoDetalle",
  components: {},
  data() {
    return {
      data_viaje: {
        vehiculo: {
          tipo_vehiculo: {},
        },
        conductor: {},
        solicitudes: {},
      },
      calificaciones: {},
      traza_viaje: {},
      origen: {},
      destino: {},
      center: {},
      zoom: 10,
      markers: [],
      markers_viaje: [],
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      mensaje_error: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      locations: {},
      poligonos: [],
      circunferencias: [],
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    async llenar_modal(item) {
      this.data_viaje = item;
    },

    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },

    getTraza() {
      if (this.data_viaje != null) {
        this.$parent.cargando = true;
        this.solicitud_id = this.data_viaje.id;
        const params = {
          vehiculo_id: this.data_viaje.vehiculo_id,
          fecha_inicial: this.data_viaje.solicitudes.fecha_hora_inicio_servicio,
          fecha_final: this.data_viaje.solicitudes.fecha_hora_fin_servicio,
        };

        axios
          .get("/api/tep/SolicitudesTransporte/trazabilidad", {
            params,
          })
          .then((response) => {
            if (!response.data.msg_error) {
              this.traza_viaje = response.data.traza;
              const datos_mapa = response.data.datos_mapa;
              this.center = {
                lat: parseFloat(datos_mapa.centro_mapa.latitud),
                lng: parseFloat(datos_mapa.centro_mapa.longitud),
              };
              this.zoom = datos_mapa.zoom;
              this.mensaje_error = null;
              this.getMapa();
            } else {
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.center = {
                lat: parseFloat(this.data_viaje.lat_origen),
                lng: parseFloat(this.data_viaje.long_origen),
              };
              this.mensaje_error = response.data.msg_error;
              this.traza_viaje = null;
              this.zoom = 16;
              this.$parent.cargando = false;
            }
          });
      }
    },

    limpiarMapa() {
      this.markers = [];
      this.markers_viaje = [];
    },

    async getMapa() {
      let conductor_inicio = null;
      let conductor_destino = null;
      let user_inicio = null;
      let user_destino = null;
      let info_llegada = null;

      if (this.data_viaje.lat_long_ini_conductor != null) {
        let conductor_ini = this.data_viaje.lat_long_ini_conductor.split(",");

        conductor_inicio = {
          lat: parseFloat(conductor_ini[0]),
          lng: parseFloat(conductor_ini[1]),
        };
      }
      if (this.data_viaje.lat_long_fin_conductor != null) {
        let conductor_fin = this.data_viaje.lat_long_fin_conductor.split(",");

        conductor_destino = {
          lat: parseFloat(conductor_fin[0]),
          lng: parseFloat(conductor_fin[1]),
        };
      }
      if (this.data_viaje.lat_long_ini_user != null) {
        let user_ini = this.data_viaje.lat_long_ini_user.split(",");

        user_inicio = {
          lat: parseFloat(user_ini[0]),
          lng: parseFloat(user_ini[1]),
        };
      }
      if (this.data_viaje.lat_long_fin_user != null) {
        let user_fin = this.data_viaje.lat_long_fin_user.split(",");

        user_destino = {
          lat: parseFloat(user_fin[0]),
          lng: parseFloat(user_fin[1]),
        };
      }
      if (this.data_viaje.lat_long_llegada != null) {
        let llegada = this.data_viaje.lat_long_llegada.split(",");

        info_llegada = {
          lat: parseFloat(llegada[0]),
          lng: parseFloat(llegada[1]),
        };
      }

      await this.addMarkers(
        conductor_inicio,
        conductor_destino,
        user_inicio,
        user_destino,
        info_llegada
      );
    },

    async addMarkers(
      conductor_inicio,
      conductor_destino,
      user_inicio,
      user_destino,
      info_llegada
    ) {
      await this.limpiarMapa();
      await this.traza_viaje.forEach((traza) => {
        const punto = {
          lat: parseFloat(traza.dblLatitud),
          lng: parseFloat(traza.dblLongitud),
        };
        const info = `
          <div class="wrapper">
            <h3 style="font-size: 15px" class="text-center">Vehículo: ${traza.strPlaca}</h3>
            <hr />
            <b>Fecha reporte:</b> ${traza.dteFechaReporte}<br />
            <b>Posicion:</b> ${traza.dblLatitud}, ${traza.dblLongitud} <br />
            <b>Velocidad:</b> ${traza.intVelocidadInstantanea} Km/h<br />
            <b>Transportadora:</b> ${traza.strDocTransportadora}<br />
            <b>Distancia Recorrida:</b> ${traza.intDistanciaRecorrida}
          </div>
        `;
        this.markers_viaje.push({
          id: traza.id,
          position: punto,
          info,
          icon: "/img/icon_viaje.png", //img\icon_viaje.png
        });
      });

      // Datos del Origen Conductor
      const info_origen_con = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Inicio Conductor</h3>         
        </div>
      `;
      await this.markers.push({
        position: conductor_inicio,
        info: info_origen_con,
        icon: "/img/volante1.png",
      });

      // Datos del Destino Conductor
      const info_destino_con = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Fin Conductor</h3>         
        </div>
      `;
      await this.markers.push({
        position: conductor_destino,
        info: info_destino_con,
        icon: "/img/volante2.png",
      });

      // Datos del Origen User
      const info_origen_user = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Inicio Usuario</h3>         
        </div>
      `;
      await this.markers.push({
        position: user_inicio,
        info: info_origen_user,
        icon: "/img/user1.png",
      });

      // Datos del Destino User
      const info_destino_user = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Fin Usuario</h3>          
        </div>
      `;
      await this.markers.push({
        position: user_destino,
        info: info_destino_user,
        icon: "/img/user.png",
      });

      // Datos LLegada
      const llegada = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Llegada</h3>         
        </div>
      `;
      await this.markers.push({
        position: info_llegada,
        info: llegada,
        icon: "/img/icon_punto_g.png",
      });

      this.$parent.cargando = false;
    },

    async showGeocercas() {
      this.cargando = true;
      await axios
        .get("/api/tep/mapas/showGeocercasZona")
        .then((response) => {
          this.cargando = false;
          this.locations = response.data.gps_fin;
          this.locations.geocerca_poli.forEach((geocerca) => {
            if (geocerca.puntos.length > 0) {
              let puntos = [];
              geocerca.puntos.forEach((punto) => {
                puntos.push({
                  lng: parseFloat(punto.longitud),
                  lat: parseFloat(punto.latitud),
                });
              });

              this.poligonos.push({
                id: geocerca.id,
                name: geocerca.desc,
                path: puntos,
                info: geocerca.ventana,
                // color: geocerca.color,
              });
            }
          });
          for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
            if (this.locations.geocerca_cir[j]["lat"] !== "") {
              this.circunferencias.push({
                center: {
                  lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                  lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                },
                radius: parseFloat(this.locations.geocerca_cir[j]["radio"]),
                info: this.locations.geocerca_cir[j]["ventana"],
              });
            }
          }
        })
        .catch(function(error) {
          this.cargando = false;
        });
    },

    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //comprueba si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
};
</script>
<style>
.div_centrado {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
</style>
