var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082"},attrs:{"id":"modal-form-detalle-tepviajeinterno"}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Detalle Viaje")]),_c('button',{ref:"closeModal1",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body p-0 pt-1"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(0),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Trazabilidad","data-toggle":"tab","href":"#Trazabilidad"},on:{"click":function($event){_vm.getTraza(), _vm.showGeocercas()}}},[_vm._v("Trazabilidad ")])])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[(_vm.data_viaje)?_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Viajes"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"position-relative p-3 m-2 bg-light",staticStyle:{"font-size":"0.85em"}},[_c('div',{staticClass:"ribbon-wrapper ribbon-lg"},[_c('div',{staticClass:"ribbon",class:_vm.data_viaje.estado == 1
                              ? 'badge-info'
                              : _vm.data_viaje.estado == 2
                              ? 'badge-warning'
                              : _vm.data_viaje.estado == 3
                              ? 'badge-success'
                              : _vm.data_viaje.estado == 4
                              ? 'badge-primary'
                              : _vm.data_viaje.estado == 5
                              ? 'badge-info'
                              : _vm.data_viaje.estado == 6
                              ? 'badge-danger'
                              : _vm.data_viaje.estado == 7
                              ? 'badge-danger'
                              : _vm.data_viaje.estado == 8
                              ? 'badge-primary'
                              : 'badge-secundary'},[_vm._v(" "+_vm._s(_vm.data_viaje.nEstado)+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-white d-flex flex-fill"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(2),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.id)+" ")])]),_c('div',{staticClass:"row"},[(_vm.data_viaje.user)?_c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Usuario Responsable:")])]):_vm._e(),(_vm.data_viaje.user)?_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.user.name)+" ")]):_vm._e()]),_c('div',{staticClass:"row"},[_vm._m(3),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.direccion_origen)+" ")])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.direccion_destino)+" ")])]),_c('div',{staticClass:"row"},[_vm._m(5),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.transportadora ? _vm.data_viaje.transportadora.razon_social : "")+" ")])]),(_vm.data_viaje.razon_cancelacion)?_c('div',{staticClass:"row"},[_vm._m(6),_c('p',{staticClass:"col-md-6 text-danger"},[_vm._v(" "+_vm._s(_vm.data_viaje.razon_cancelacion)+" ")])]):_vm._e()])])]),_c('div',{staticClass:"col-md-6 d-flex align-items-stretch flex-column"},[(_vm.data_viaje.tep_fechas_viajes)?_c('div',{staticClass:"card bg-white d-flex flex-fill"},[_vm._m(7),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_vm._m(8),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.fecha_ini)+" ")])]),_c('div',{staticClass:"row"},[_vm._m(9),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.fecha_fin)+" ")])]),(
                                  _vm.data_viaje.solicitudes.tipo_operacion != 3
                                )?_c('div',{staticClass:"row"},[_vm._m(10),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.tep_fechas_viajes.length > 0 ? _vm.data_viaje.tep_fechas_viajes[0] .fecha_llegada_app_conductor : "")+" ")])]):_vm._e(),_c('div',{staticClass:"row"},[_vm._m(11),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.tep_fechas_viajes.length > 0 ? _vm.data_viaje.tep_fechas_viajes[0] .fecha_ini_app_user : "")+" ")])]),_c('div',{staticClass:"row"},[_vm._m(12),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.tep_fechas_viajes.length > 0 ? _vm.data_viaje.tep_fechas_viajes[0] .fecha_ini_app_conductor : "")+" ")])]),_c('div',{staticClass:"row"},[_vm._m(13),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.tep_fechas_viajes.length > 0 ? _vm.data_viaje.tep_fechas_viajes[0] .fecha_fin_app_user : "")+" ")])]),_c('div',{staticClass:"row"},[_vm._m(14),_c('p',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.data_viaje.tep_fechas_viajes.length > 0 ? _vm.data_viaje.tep_fechas_viajes[0] .fecha_fin_app_conductor : "")+" ")])])])]):_vm._e()]),_c('div',{staticClass:"col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-white d-flex flex-fill"},[_vm._m(15),_c('div',{staticClass:"card-body"},[(_vm.data_viaje.vehiculo)?_c('div',{staticClass:"row"},[_c('div',{class:_vm.data_viaje.vehiculo.link_fotografia
                                      ? 'col-md-7'
                                      : 'col-md-12'},[_c('h2',{staticClass:"lead"},[_c('b',[_vm._v(_vm._s(_vm.data_viaje.vehiculo.placa))])]),(_vm.data_viaje.transportadora)?_c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Transportadora: ")]),_vm._v(" "+_vm._s(_vm.data_viaje.transportadora.razon_social)+" ")]):_vm._e(),_c('ul',{staticClass:"ml-4 mb-0 fa-ul text-muted"},[_c('li',{staticClass:"small"},[_vm._m(16),_vm._v(" Tipo Vehículo: "+_vm._s(_vm.data_viaje.vehiculo.tipo_vehiculo .nombre)+" ")])])]),(_vm.data_viaje.vehiculo.link_fotografia)?_c('div',{staticClass:"col-md-5 text-center"},[_c('img',{staticClass:"img-bordered img-rounded img-fluid",attrs:{"src":_vm.uri_docs +
                                        _vm.data_viaje.vehiculo.link_fotografia,"alt":"Vehículo"}})]):_vm._e()]):_c('div',[_vm._m(17)])])])]),(_vm.data_viaje.solicitudes.tipo_operacion != 3)?_c('div',{staticClass:"col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-white d-flex flex-fill"},[_vm._m(18),_c('div',{staticClass:"card-body"},[(_vm.data_viaje.conductor)?_c('div',{staticClass:"row"},[_c('div',{class:_vm.data_viaje.conductor.link_fotografia
                                      ? 'col-md-7'
                                      : 'col-md-12'},[_c('h2',{staticClass:"lead"},[_c('b',[_vm._v(_vm._s(_vm.data_viaje.conductor.nombres)+" "+_vm._s(_vm.data_viaje.conductor.apellidos)+" ")])]),(_vm.data_viaje.encuesta)?_c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Calificación: ")]),_vm._v(" "+_vm._s(_vm.encu.calificacion)+" "),_c('i',{staticClass:"fas fa-star"})]):_c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Calificación: ")]),_vm._v(" Sin calificación ")]),_c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Documento: ")]),_vm._v(" "+_vm._s(_vm.data_viaje.conductor.numero_documento)+" ")]),_c('ul',{staticClass:"ml-4 mb-0 fa-ul text-muted"},[_c('li',{staticClass:"small"},[_vm._m(19),_vm._v(" E-mail: "+_vm._s(_vm.data_viaje.conductor.email)+" ")]),_c('li',{staticClass:"small"},[_vm._m(20),_vm._v(" Celular Principal: "+_vm._s(_vm.data_viaje.conductor.celular_principal)+" ")]),_c('li',{staticClass:"small"},[_vm._m(21),_vm._v(" Celular Alterno: "+_vm._s(_vm.data_viaje.conductor.celular_alterno)+" ")])])]),(_vm.data_viaje.conductor.link_fotografia)?_c('div',{staticClass:"col-md-5 text-center"},[_c('img',{staticClass:"img-bordered img-rounded img-fluid",attrs:{"src":_vm.uri_docs +
                                        _vm.data_viaje.conductor.link_fotografia,"alt":"Conductor"}})]):_vm._e()]):_c('div',[_vm._m(22)])])])]):_vm._e()])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"position-relative p-3 m-2 bg-light"},[_vm._m(23),(
                          _vm.data_viaje.odometro_ini ||
                            _vm.data_viaje.foto_odometro_ini ||
                            _vm.data_viaje.odometro_fin ||
                            _vm.data_viaje.foto_odometro_fin
                        )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[(_vm.data_viaje.odometro_ini)?_c('div',{staticClass:"col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-white d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"},[_vm._v(" Odómetro Inicial ")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h2',{staticClass:"lead"},[_c('b',[_vm._v(_vm._s(_vm.data_viaje.odometro_ini))])])]),(_vm.data_viaje.foto_odometro_ini)?_c('div',{staticClass:"col-md-8 text-center"},[_c('img',{staticClass:"img-bordered img-rounded img-fluid",attrs:{"src":_vm.uri_docs +
                                            _vm.data_viaje.foto_odometro_ini,"alt":"Odómetro Inicial"},on:{"click":function($event){return _vm.getImage(
                                            _vm.data_viaje.foto_odometro_ini
                                          )}}})]):_vm._e()])])])]):_vm._e(),(_vm.data_viaje.odometro_fin)?_c('div',{staticClass:"col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-white d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"},[_vm._v(" Odómetro Final ")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h2',{staticClass:"lead"},[_c('b',[_vm._v(_vm._s(_vm.data_viaje.odometro_fin))])])]),(_vm.data_viaje.foto_odometro_fin)?_c('div',{staticClass:"col-md-8 text-center"},[_c('img',{staticClass:"img-bordered img-rounded img-fluid",attrs:{"src":_vm.uri_docs +
                                            _vm.data_viaje.foto_odometro_fin,"alt":"Odómetro Final"},on:{"click":function($event){return _vm.getImage(
                                            _vm.data_viaje.foto_odometro_fin
                                          )}}})]):_vm._e()])])])]):_vm._e()])])]):_c('div',[_vm._m(24)])])])])])]):_c('div',{staticClass:"tab-pane",attrs:{"id":"Viajes"}},[_vm._m(25)]),(_vm.data_viaje != null && _vm.traza_viaje != null)?_c('div',{staticClass:"tab-pane",attrs:{"id":"Trazabilidad"}},[(_vm.traza_viaje)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('gmap-map',{staticStyle:{"width":"100%","height":"650px"},attrs:{"center":_vm.center,"zoom":_vm.zoom}},[_vm._l((_vm.markers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position,"icon":m.icon,"animation":2},on:{"click":function($event){return _vm.toggleInfoWindow(m, index, m.info)}}})}),_vm._l((_vm.markers_viaje),function(n,index){return _c('gmap-marker',{key:index,attrs:{"position":n.position,"icon":n.icon,"animation":3},on:{"click":function($event){return _vm.toggleInfoWindow(n, index, n.info)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoContent)}})]),_vm._l((_vm.poligonos),function(poligono){return _c('gmap-polygon',{key:poligono.id,attrs:{"options":{ fillColor: poligono.color },"paths":poligono.path,"editable":false,"draggable":false},on:{"click":function($event){return _vm.toggleInfoWindowPoli(
                            poligono,
                            poligono.id,
                            poligono.info
                          )}}})}),_vm._l((_vm.circunferencias),function(circular){return _c('gmap-circle',{key:circular.id,attrs:{"center":circular.center,"radius":circular.radius,"info":circular.info},on:{"click":function($event){return _vm.toggleInfoWindowCir(
                            circular,
                            circular.id,
                            circular.info
                          )}}})})],2)],1),_c('div',{staticClass:"col-md-4"},[_c('table',{staticClass:"table table-sm table-striped table-bordered"},[_vm._m(26),_c('tbody',[_c('tr',[_vm._m(27),_c('td',[_vm._v(" "+_vm._s(_vm.data_viaje.direccion_origen)+" ")])]),_c('tr',[_vm._m(28),_c('td',[_vm._v(" "+_vm._s(_vm.data_viaje.direccion_destino)+" ")])]),_c('tr',[_vm._m(29),_c('td',[_vm._v(" Inicio Conductor - "+_vm._s(_vm.data_viaje.lat_long_ini_conductor)+" ")])]),_c('tr',[_vm._m(30),_c('td',[_vm._v(" Fin Conductor - "+_vm._s(_vm.data_viaje.lat_long_fin_conductor)+" ")])]),_c('tr',[_vm._m(31),_c('td',[_vm._v(" Inicio Usuario - "+_vm._s(_vm.data_viaje.lat_long_ini_user)+" ")])]),_c('tr',[_vm._m(32),_c('td',[_vm._v(" Fin Usuario - "+_vm._s(_vm.data_viaje.lat_long_fin_user)+" ")])]),_c('tr',[_vm._m(33),_c('td',[_vm._v("Llegada - "+_vm._s(_vm.data_viaje.lat_long_llegada))])])])])])])]):_vm._e()]):_c('div',{staticClass:"tab-pane",attrs:{"id":"Trazabilidad"}},[_vm._m(34)])])])]),_c('div',{staticClass:"modal-footer justify-content-between"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Viajes","data-toggle":"tab","href":"#Viajes"}},[_vm._v("VIAJE ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title"},[_vm._v("Datos Servicio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Numero Servicio:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Dirección origen:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Dirección destino:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Transportadora:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Razón Cancelación:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title"},[_vm._v("Fechas")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Fecha inicio:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Fecha fin:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Fecha llegada conductor:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Fecha inicio usuario:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Fecha inicio conductor:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Fecha fin usuario:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-md-6"},[_c('b',[_vm._v("Fecha fin conductor:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title"},[_vm._v(" Vehículo ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-li"},[_c('i',{staticClass:"fas fa-lg fa-car"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 text-center"},[_c('h4',{staticClass:"text-muted"},[_vm._v("Sin asignar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title"},[_vm._v(" Conductor ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-li"},[_c('i',{staticClass:"fas fa-lg fa-envelope"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-li"},[_c('i',{staticClass:"fas fa-lg fa-mobile"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-li"},[_c('i',{staticClass:"fas fa-lg fa-phone"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 text-center"},[_c('h4',{staticClass:"text-muted"},[_vm._v("Sin asignar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ribbon-wrapper ribbon-lg"},[_c('div',{staticClass:"ribbon bg-info"},[_vm._v("Odómetro")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 text-center p-5"},[_c('h4',{staticClass:"text-muted"},[_vm._v("Sin registrar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"info-box bg-gradient-warning"},[_c('span',{staticClass:"info-box-icon"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v("No tiene un viaje asignado")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v("Viaje")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge bg-olive"},[_vm._v("Origen")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge bg-pink"},[_vm._v("Destino")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('img',{staticClass:"figure-img img-fluid rounded",attrs:{"src":"/img/volante1.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('img',{staticClass:"figure-img img-fluid rounded",attrs:{"src":"/img/volante2.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('img',{staticClass:"figure-img img-fluid rounded",attrs:{"src":"/img/user1.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('img',{staticClass:"figure-img img-fluid rounded",attrs:{"src":"/img/user.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-center"},[_c('img',{staticClass:"figure-img img-fluid rounded",attrs:{"src":"/img/icon_punto_g.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"info-box bg-gradient-warning"},[_c('span',{staticClass:"info-box-icon"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v("Usted aun no tiene trazabilidad")])])])])
}]

export { render, staticRenderFns }